.app {
  box-sizing: border-box;
}

.container {
  display: flex;
  height: 100vh;
  flex-direction: row;
}

h1 {
  font-size: 60px;
  font-weight: bold;
}

.textContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 30px;
  flex: 1;
  box-sizing: border-box;
}

.imgContainer {
  height: 100%;
  flex: 1;
}

.overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.contact{
  margin-top: 40px;
}

@media only screen and (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
  h1 {
    font-size: 40px;
  }
}